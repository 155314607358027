import * as React from "react";
import styled from "styled-components";
import Img from "gatsby-image";
import SEO from "src/components/SEO.js"
import Wrapper from "src/components/global/Wrapper.js";
import colors from "src/assets/styles/colors.js";
import BodyText from "src/components/typography/BodyText.js";
import Title from "src/components/typography/Title.js";
import BtnLine from "src/components/global/btn/BtnLine.js";
import BtnBasic from "src/components/global/btn/BtnBasic.js";
import mediaQuery from "src/assets/styles/mediaQuery";
import sizes from "src/assets/styles/sizes.js";
import HighlightedProjectsSlider from "src/components/Home/HighlightedProjectsSlider/HighlightedProjectsSlider.js";
import CompanyCatalogBox from "src/components/Home/CompanyCatalogBox.js";
import Slider from "src/components/global/Slider.js";
import Testimonials from "src/components/global/Testimonials.js";
import Cta from "src/components/global/Cta.js";

const Header = styled(Wrapper)`
  padding-top: 180px;
  height: calc(100vh - 180px);
  position: relative;
  /* overflow: hidden; */
  @media (max-width: ${mediaQuery.tablet}) {
    height: auto;

    background-color: ${colors.lightGrey};
    padding-left: ${sizes.smallMargin}px;
    padding-right: ${sizes.smallMargin}px;
    padding-top: 100px;
    ::after {
      content: "";
      position: absolute;
      bottom: -100px;
      left: 0;
      height: 100px;
      width: 100vw;
      background-color: ${colors.lightGrey};
    }
  }
  @media (min-width: ${mediaQuery.max}) {
    height: calc(100vh - 12.79vw);
    padding-top: 12.79vw;
  }
`;

const HistoryWrapper = styled(Wrapper)`
  margin-top: 50px;
  margin-bottom: 150px;
  display: flex;
  justify-content: space-between;
  @media (max-width: ${mediaQuery.tablet}) {
    flex-direction: column;
    margin-top: 20px;
    margin-bottom: 70px;
  }
  @media (min-width: ${mediaQuery.max}) {
    margin-top: 3.466vw;
    margin-bottom: 10.399vw;
  }
`;

const HistoryImg = styled(Img)`
  width: calc(58.33% - 15px);
  @media (max-width: ${mediaQuery.laptop}) {
    width: calc(50% - 15px);
  }
  @media (max-width: ${mediaQuery.tablet}) {
    width: 100%;
  }
  @media (min-width: ${mediaQuery.max}) {
    width: calc(58.33% - 1.039vw);
  }
`;

const HistoryRight = styled.div`
  width: calc(37.5% - 15px);
  justify-content: space-between;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: flex-start;
  @media (max-width: ${mediaQuery.laptop}) {
    width: calc(50% - 15px);
  }
  @media (max-width: ${mediaQuery.tablet}) {
    width: 100%;
  }
  @media (min-width: ${mediaQuery.max}) {
    width: calc(37.5% - 1.039vw);
  }
`;

const HistorySubtitle = styled(BodyText)`
  margin-bottom: 5px;
  @media (max-width: ${mediaQuery.tablet}) {
    margin-top: 20px;
  }
  @media (min-width: ${mediaQuery.max}) {
    margin-bottom: 0.346vw;
  }
`;

const HistoryTitle = styled(Title)`
  margin-bottom: 20px;
  @media (min-width: ${mediaQuery.max}) {
    margin-bottom: 1.386vw;
  }
`;

const HistoryDescription = styled(BodyText)`
  margin-left: 50px;
  @media (max-width: ${mediaQuery.mobile}) {
    margin-left: 0px;
  }
  @media (min-width: ${mediaQuery.max}) {
    margin-left: 3.466vw;
  }
`;

const HistoryBtn = styled(BtnLine)`
  margin-top: 50px;
  margin-left: 92px;
  @media (max-width: ${mediaQuery.mobile}) {
    align-self: flex-end;
    margin-top: 20px;
  }
  @media (min-width: ${mediaQuery.max}) {
    margin-top: 3.466vw;
    margin-left: 6.239vw;
  }
`;

const HitoryClaimWrapper = styled(Wrapper)`
  padding-bottom: 20px;
  margin-bottom: 160px;
  position: relative;
  ::after {
    content: "";
    width: 8.33%;
    background-color: ${colors.mainColor};
    position: absolute;
    bottom: -10px;
    height: 1px;
  }
  @media (max-width: ${mediaQuery.tablet}) {
    margin-bottom: 70px;
  }
  @media (min-width: ${mediaQuery.max}) {
    padding-bottom: 1.386vw;
    margin-bottom: 11.093vw;
    ::after {
      height: 0.0693vw;
    }
  }
`;

const CompanysCatalogWrapper = styled(Wrapper)`
  display: flex;
  flex-wrap: wrap;
  position: relative;
  margin-bottom: 100px;
  @media (max-width: ${mediaQuery.mobile}) {
    width: calc(100% - ${sizes.mobileSecondMargin * 2}px);
    margin: 0 ${sizes.mobileSecondMargin}px;
  }
  @media (min-width: ${mediaQuery.max}) {
    margin-bottom: 6.93vw;
  }
`;

const MessageWrapper = styled.div`
  padding: 100px 0;
  background-color: ${colors.lightGrey};
  @media (min-width: ${mediaQuery.max}) {
    padding: 6.93vw 0;
  }
`;

const MessageBox = styled(Wrapper)`
  display: flex;
  justify-content: space-between;
  background-color: ${colors.mainColor};
  padding: 50px 0;
  @media (max-width: ${mediaQuery.tablet}) {
    flex-direction: column;
    align-items: center;
    padding: 20px 0 50px 0;
  }
  @media (min-width: ${mediaQuery.max}) {
    padding: 3.466vw 0;
  }
`;

const MessageImg = styled(Img)`
  width: 25%;
  margin-left: 8.33%;
  img {
    object-fit: contain !important;
  }
  @media (max-width: ${mediaQuery.tablet}) {
    width: calc(100% - 80px);
    margin-left: 0px;
  }
`;

const MessageRight = styled.div`
  width: 50%;
  margin-right: 8.33%;
  @media (max-width: ${mediaQuery.tablet}) {
    width: calc(100% - 80px);
    margin-right: 0%;
    p {
      text-align: center;
    }
    display: flex;
    align-items: center;
    flex-direction: column;
  }
`;

const MessageTitle = styled(Title)`
  margin-bottom: 15px;
  width: 83.33%;
  color: ${colors.white};
  @media (max-width: ${mediaQuery.tablet}) {
    text-align: center;
    width: 100%;
    margin-right: 0%;
  }
  @media (min-width: ${mediaQuery.max}) {
    padding: 1.039vw 0;
  }
`;

const MessageBtn = styled(BtnBasic)`
  color: ${colors.white};
  position: relative;
  background-color: ${colors.lightenMainColor};

  @media (max-width: ${mediaQuery.tablet}) {
    margin-top: 35px;
  }
`;

const ProcessWrapper = styled(Wrapper)`
  margin-top: 100px;
  margin-bottom: 70px;
  overflow-x: auto;
  margin-left: ${sizes.xlMargin}px;
  width: calc(100% - ${sizes.xlMargin}px);
  @media (max-width: ${mediaQuery.desktop}) {
    width: calc(100% - ${sizes.bigMargin}px);
    margin-left: ${sizes.bigMargin}px;
  }
  @media (max-width: ${mediaQuery.tablet}) {
    width: calc(100% - ${sizes.smallMargin}px);
    margin-left: ${sizes.smallMargin}px;
  }
  @media (min-width: ${mediaQuery.max}) {
    margin-top: 6.933vw;
    margin-bottom: 4.853vw;
    margin-left: 9.706vw;
    width: calc(100% - 9.706vw);
  }
`;

const ProcessTitle = styled(Title)`
  margin-bottom: 40px;
  @media (max-width: ${mediaQuery.tablet}) {
    margin-bottom: 20px;
  }
  @media (min-width: ${mediaQuery.max}) {
    margin-bottom: 2.773vw;
  }
`;

const TestimonialsWrapper = styled(Wrapper)``;

const IndexPage = (props) => {
  return (
    <>
        <SEO title={props.data.strapiHomePage.SEO.Title}
        description={props.data.strapiHomePage.SEO.Description}/>
      <Header>
        <HighlightedProjectsSlider />
      </Header>
      <HistoryWrapper>
        <HistoryImg
          data-sal="slide-up"
          data-sal-easing="ease"
          data-sal-duration="1000"
          fluid={props.data.strapiHomePage.History_img.childImageSharp.fluid}
        />
        <HistoryRight>
          <div>
            <HistorySubtitle
              data-sal="slide-up"
              data-sal-easing="ease"
              data-sal-duration="1000"
              color={colors.mainColor}
            >
              {props.data.strapiHomePage.Hisotry_subTitle}
            </HistorySubtitle>
            <HistoryTitle
              data-sal="slide-up"
              data-sal-easing="ease"
              data-sal-duration="1000"
            >
              {props.data.strapiHomePage.History_title}
            </HistoryTitle>
            <HistoryDescription
              data-sal="slide-up"
              data-sal-easing="ease"
              data-sal-duration="1000"
            >
              {props.data.strapiHomePage.History_description}
            </HistoryDescription>
          </div>
          <HistoryBtn
            data-sal="slide-up"
            data-sal-easing="ease"
            data-sal-duration="1000"
          >
            o nas
          </HistoryBtn>
        </HistoryRight>
      </HistoryWrapper>
      <HitoryClaimWrapper
        data-sal="slide-up"
        data-sal-easing="ease"
        data-sal-duration="1000"
      >
        <Title>{props.data.strapiHomePage.History_claim}</Title>
      </HitoryClaimWrapper>
      <CompanysCatalogWrapper>
        {props.data.strapiHomePage.Company_catalog.map((document) => (
          <CompanyCatalogBox data={document} />
        ))}
      </CompanysCatalogWrapper>
      <MessageWrapper>
        <MessageBox>
          <MessageImg
            data-sal="slide-up"
            data-sal-easing="ease"
            data-sal-duration="1000"
            fluid={props.data.strapiHomePage.Message_Img.childImageSharp.fluid}
          />
          <MessageRight>
            <MessageTitle
              data-sal="slide-up"
              data-sal-easing="ease"
              data-sal-duration="1000"
            >
              {props.data.strapiHomePage.Message_title}
            </MessageTitle>
            <BodyText
              data-sal="slide-up"
              data-sal-easing="ease"
              data-sal-duration="1000"
              color={colors.white}
            >
              {props.data.strapiHomePage.Message_description}
            </BodyText>
            <MessageBtn
              data-sal="slide-up"
              data-sal-easing="ease"
              data-sal-duration="1000"
              href={`mailto: ${props.data.strapiContactInformation.Mail}`}
            >
              Wyślij wiadomość
            </MessageBtn>
          </MessageRight>
        </MessageBox>
      </MessageWrapper>
      <ProcessWrapper>
        <ProcessTitle
          data-sal="slide-up"
          data-sal-easing="ease"
          data-sal-duration="1000"
        >
          {props.data.strapiHomePage.Process_title}
        </ProcessTitle>
        <Slider images={props.data.strapiHomePage.Process_gallery} />
      </ProcessWrapper>
      <TestimonialsWrapper>
        <Testimonials data={props.data.allStrapiTestimonials} />
      </TestimonialsWrapper>
      <Cta />
    </>
  );
};

export const KontaktQuery = graphql`
  query IndexQuery {
    strapiHomePage {
      Hisotry_subTitle
      History_description
      History_img {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      History_title
      History_claim
      Company_catalog {
        Logo {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        Pdf {
          publicURL
        }
      }
      Message_Img {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      Message_description
      Message_title
      Process_title
      Process_gallery {
        localFile {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      SEO {
        Description
        Title
      }
    }
    strapiContactInformation {
      Mail
      Telephone
    }
    allStrapiTestimonials(filter: { Home_page: { eq: true } }) {
      edges {
        node {
          Date
          Description
          Name
        }
      }
    }
  }
`;

export default IndexPage;
