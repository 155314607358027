import React from "react";
import styled from "styled-components";
import Img from "gatsby-image";
import colors from "src/assets/styles/colors.js";
import typographySizes from "src/assets/styles/typographySizes.js";
import mediaQuery from "src/assets/styles/mediaQuery";
import BtnArrowSvg from "src/assets/svg/btn-arrow.svg";

const ImgWrapper = styled.div`
  width: 100%;
  height: 10vw;
  border: solid #bcbcbc 1px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  transition: all 0.5s;
  @media (max-width: ${mediaQuery.tablet}) {
    height: 40vw;
  }
  @media (min-width: ${mediaQuery.max}) {
    padding: 0.069vw 0;
  }
`;

const StyledImg = styled(Img)`
  height: 50%;
  width: 80%;
  img {
    object-fit: contain !important;
  }
`;

const CompanyStyledArrow = styled(BtnArrowSvg)`
  transform: rotate(45deg) translate(0px, 2px);
  transform-origin: center;
  margin-left: 5px;
  position: relative;
  transition: all 0.5s;
  @media (min-width: ${mediaQuery.max}) {
    margin-left: 0.346vw;

    width: 0.756vw;
    height: 0.754vw;
  }
`;

const DownloadLink = styled.p`
  font-size: ${typographySizes.s}px;

  margin-top: 15px;
  text-decoration: none;
  cursor: pointer;
  transition: all 0.5s;
  color: ${colors.darkGrey};
  @media (max-width: ${mediaQuery.mobile}) {
    font-size: ${typographySizes.xs}px;
  }
  :hover {
    color: ${colors.mainColor};
    ${ImgWrapper} {
      top: -50px;
      display: none;
      transition: all 1s cubic-bezier(0.645, 0.045, 0.355, 1);
    }
    ${CompanyStyledArrow} {
      transform: rotate(45deg) translate(5px, -3px);
      transition: all 0.5s;
    }
  }
  @media (min-width: ${mediaQuery.max}) {
    font-size: ${typographySizes.maxS}vw;
    line-height: 1.33;
    margin-top: 1.039vw;
    :hover {
      ${ImgWrapper} {
        top: -3.466vw;
      }
      ${CompanyStyledArrow} {
        transform: rotate(45deg) translate(0.346vw, -0.207vw);
      }
    }
  }
`;

const LinkWrapper = styled.a`
  width: calc(25% - 11.25px);
  margin-right: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
  margin-bottom: 15px;
  :last-of-type {
    margin-right: 0;
  }
  :hover {
    ${DownloadLink} {
      color: ${colors.mainColor};
      transition: all 0.5s;
    }
    ${ImgWrapper} {
      transform: translateY(-20px);
      transition: all 0.5s cubic-bezier(0.645, 0.045, 0.355, 1);
    }
    ${CompanyStyledArrow} {
      transform: rotate(45deg) translate(5px, -3px);
      transition: all 0.5s;
    }
  }
  @media (max-width: ${mediaQuery.tablet}) {
    width: calc(50% - 7.5px);
    margin-bottom: 30px;
    :nth-child(2n) {
      margin-right: 0;
    }
    :hover {
      ${DownloadLink} {
        color: ${colors.mainColor};
        transition: all 0.5s;
      }
      ${ImgWrapper} {
        transform: translateY(0px);
        transition: all 0.5s cubic-bezier(0.645, 0.045, 0.355, 1);
      }
      ${CompanyStyledArrow} {
        transform: rotate(45deg) translate(5px, -3px);
        transition: all 0.5s;
      }
    }
  }
  @media (max-width: ${mediaQuery.mobile}) {
    width: 100%;
    margin-right: 0;
  }
  @media (min-width: ${mediaQuery.max}) {
    width: calc(25% - 0.779vw);
    margin-right: 1.039vw;

    margin-bottom: 1.039vw;
    :hover {
      ${ImgWrapper} {
        transform: translateY(-1.386vw);
        transition: all 0.5s cubic-bezier(0.645, 0.045, 0.355, 1);
      }
      ${CompanyStyledArrow} {
        transform: rotate(45deg) translate(0.346vw, -0.207vw);
        transition: all 0.5s;
      }
    }
  }
`;

const CompanyCatalogBox = ({ data }) => (
  <LinkWrapper
    data-sal="slide-up"
    data-sal-easing="ease"
    data-sal-duration="1000"
    href={data.Pdf.publicURL}
    target="blank"
  >
    <ImgWrapper>
      <StyledImg
        objectPosition="50% 50%"
        fluid={data.Logo.childImageSharp.fluid}
      />
    </ImgWrapper>
    <DownloadLink href={data.Pdf.publicURL} target="blank">
      Pobierz katalog <CompanyStyledArrow />
    </DownloadLink>
  </LinkWrapper>
);

export default CompanyCatalogBox;
