import React from "react";
import { StaticQuery, graphql } from "gatsby";
import slugify from "slugify";
import { Link } from "gatsby";

import Img from "gatsby-image";
import Arrow from "src/assets/svg/arrow.svg";
import MobileArrowHeader from "src/assets/svg/mobile-arrow-header.svg";

import styled from "styled-components";
import Title from "src/components/typography/Title.js";
import BodyText from "src/components/typography/BodyText.js";
import BtnLine from "src/components/global/btn/BtnLine.js";
import BtnArrow from "src/components/global/btn/BtnArrow.js";
import colors from "src/assets/styles/colors.js";
import mediaQuery from "src/assets/styles/mediaQuery";
import sizes from "src/assets/styles/sizes.js";

const SliderWrapper = styled.div`
  width: 100%;
  height: 100%;
  align-items: flex-start;
  display: flex;

  @media (max-width: ${mediaQuery.tablet}) {
    flex-direction: column-reverse;
  }
`;

const LeftWrapper = styled.div`
  width: 50%;
  height: 100%;

  display: flex;
  flex-direction: column;
  @media (max-width: ${mediaQuery.tablet}) {
    width: 100%;
  }
`;

const InformationWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 26vw;
  justify-content: space-between;
  grid-area: Information;
  overflow-x: hidden;
  align-items: flex-start;
  @media (max-width: ${mediaQuery.tablet}) {
    height: auto;
  }
`;

const StyledTitleDesctop = styled(Title)`
  margin-top: 50px;
  width: 66.66%;
  @media (max-width: ${mediaQuery.desktop}) {
    margin-top: 0px;
    width: calc(100% - 30px);
  }
  @media (max-width: ${mediaQuery.tablet}) {
    margin-top: 0px;
    width: calc(100% - 30px);
    display: none;
  }
`;

const StyledTitleMobile = styled(Title)`
  display: none;
  @media (max-width: ${mediaQuery.tablet}) {
    display: inline-block;
    margin-bottom: 15px;
  }
`;

const Description = styled(BodyText)`
  margin-left: 16.66%;
  margin-top: 25px;

  width: 50%;
  @media (max-width: ${mediaQuery.desktop}) {
    width: 80%;
  }
  @media (max-width: ${mediaQuery.laptop}) {
    width: 80%;
    margin-left: 0%;
  }
  @media (max-width: ${mediaQuery.laptop}) {
    width: 86%;
    margin-left: 16.66%;
    margin-bottom: 50px;
  }
`;

const StyledBtnLine = styled(BtnLine)`
  margin-left: 66.66%;
  text-decoration: none;
  transition: all 0.5s;

  ::before {
    width: calc(((100vw - 280px) * 0.3333) - 15px);
  }
  :hover {
    ::before {
      transition: all 0.5s;
    }
  }
  @media (max-width: ${mediaQuery.desktop}) {
    margin-left: 50%;
    ::before {
      width: calc(((100vw - 60px) * 0.25) - 15px);
    }
  }
  @media (max-width: ${mediaQuery.tablet}) {
    margin-left: 100%;
    padding: 0;
    white-space: nowrap;
    margin-bottom: 55px;
    transform: translate(-100%, 0) !important;
    background-color: ${colors.lightGrey};
    ::before {
      right: 0;
      width: calc(100vw - ${sizes.smallMargin * 2}px);
    }
  }
`;

const ImgSliderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  grid-area: ImgSlider;
  /* height: calc(100vh - 180px); */
  height: 100%;
  overflow: hidden;
  width: 50%;
  position: relative;
  flex-shrink: 0;
  ::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 247px;
    padding: 23px 26px 9px 0;
    object-fit: contain;
    background-image: linear-gradient(
      to top,
      #ffffff,
      #ffffff 26%,
      rgba(255, 255, 255, 0.96) 44%,
      rgba(255, 255, 255, 0)
    );
  }
  @media (max-width: ${mediaQuery.tablet}) {
    flex-direction: row;
    width: 100%;
    max-height: 40vh;
    :after {
      display: none;
    }
  }
`;

const StyledImgDesctop = styled(Img)`
  flex-grow: 0;
  flex-shrink: 0;
  height: 26vw;
  margin-bottom: 30px;
  transition: all 1s cubic-bezier(0.645, 0.045, 0.355, 1);
  @media (max-width: ${mediaQuery.tablet}) {
    /* width: 100%; */
    display: none;
  }
`;
const StyledLinkImg = styled(Link)`
  width: 100%;
  flex-grow: 0;
  flex-shrink: 0;
  height: 100%;
  display: none;
  @media (max-width: ${mediaQuery.tablet}) {
    display: inline-block;
  }
`;

const StyledImgMobile = styled(Img)`
  width: 100%;
  flex-grow: 0;
  flex-shrink: 0;
  height: 100%;

  transition: all 1s cubic-bezier(0.645, 0.045, 0.355, 1);
  display: none;
  @media (max-width: ${mediaQuery.tablet}) {
    display: inline-block;
  }
`;

const NavigationWrapper = styled.div`
  align-self: start;

  margin-top: 22.5px;
  display: flex;
  height: auto;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding-bottom: 30px;
  flex: 1;
  @media (max-width: ${mediaQuery.tablet}) {
    width: 100%;
    align-items: center;
    margin-top: 0px;
  }
`;

const ArrowWrapper = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: ${mediaQuery.tablet}) {
    display: none;
  }
`;

const ArrowTop = styled.button`
  transform: rotate(180deg);
  padding: 7.5px 0;
  cursor: pointer;
  transition: all 0.5s;
  &.disable {
    opacity: 0.3;
    transition: all 0.5s;
  }
  @media (min-width: ${mediaQuery.max}) {
    padding: 0.519vw 0;
    .arrow {
      width: 1.159vw;
      height: 0.628vw;
    }
  }
`;

const ArrowBottom = styled.button`
  padding: 7.5px 0;
  cursor: pointer;
  transition: all 0.5s;
  &.disable {
    opacity: 0.3;
    transition: all 0.5s;
  }
  @media (min-width: ${mediaQuery.max}) {
    padding: 0.519vw 0;
    .arrow {
      width: 1.159vw;
      height: 0.628vw;
    }
  }
`;

const StyledBtnArrow = styled(BtnArrow)`
  margin-bottom: 30px;
`;

const ArrowLeft = styled.button`
  cursor: pointer;
  width: 38.3px;
  z-index: 0;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%) rotate(180deg) translateX(0%);
  height: 104.7px;

  transition: all 0.5s;
  &.disable {
    transform: rotate(180deg) translateX(75%) translateY(50%);
    transition: all 0.5s;
  }
  display: none;
  @media (max-width: ${mediaQuery.tablet}) {
    display: inline-block;
  }
`;

const ArrowRight = styled.button`
  cursor: pointer;
  width: 38.3px;
  height: 104.7px;
  position: absolute;
  top: 50%;

  right: 0;
  transition: all 0.5s;
  transform: translateY(-50%);
  &.disable {
    transform: translateX(75%) translateY(-50%);
    transition: all 0.5s;
  }
  display: none;
  @media (max-width: ${mediaQuery.tablet}) {
    display: inline-block;
  }
`;

class HighlightedProjects extends React.Component {
  state = {
    curentProject: 0,
    nextDisable: false,
    prevDisable: true,
  };
  nextProject = () => {
    if (
      this.state.curentProject >=
      this.props.site.allStrapiHighlightedProjects.edges.length - 1
    ) {
    } else {
      this.setState({
        curentProject: this.state.curentProject + 1,
      });
    }
    if (
      this.state.curentProject >=
      this.props.site.allStrapiHighlightedProjects.edges.length - 2
    ) {
      this.setState({
        nextDisable: true,
      });
    } else {
    }
    if (this.state.curentProject >= 0) {
      this.setState({
        prevDisable: false,
      });
    } else {
    }
  };
  prevProject = () => {
    if (this.state.curentProject <= 0) {
    } else {
      this.setState({
        curentProject: this.state.curentProject - 1,
      });
    }
    console.log(this.state.curentProject);
    console.log(this.props.site.allStrapiHighlightedProjects.edges.length - 1);
    if (
      this.state.curentProject >=
      this.props.site.allStrapiHighlightedProjects.edges.length - 1
    ) {
      this.setState({
        nextDisable: false,
      });
    } else {
    }
    if (this.state.curentProject <= 1) {
      this.setState({
        prevDisable: true,
      });
    } else {
    }
  };
  render() {
    return (
      <SliderWrapper>
        <LeftWrapper>
          <InformationWrapper>
            <div>
              <StyledTitleDesctop
                data-sal="slide-up"
                data-sal-easing="ease"
                data-sal-duration="1000"
              >
                {
                  this.props.site.allStrapiHighlightedProjects.edges[
                    this.state.curentProject
                  ].node.Name
                }
              </StyledTitleDesctop>
              <Description
                data-sal="slide-up"
                data-sal-easing="ease"
                data-sal-duration="1000"
              >
                {
                  this.props.site.allStrapiHighlightedProjects.edges[
                    this.state.curentProject
                  ].node.Description_header
                }
              </Description>
            </div>

            <StyledBtnLine
              data-sal="slide-up"
              data-sal-easing="ease"
              data-sal-duration="1000"
              to={`/realizacje/${slugify(
                this.props.site.allStrapiHighlightedProjects.edges[
                  this.state.curentProject
                ].node.Name,
                {
                  lower: true,
                }
              )}`}
            >
              <p>zobacz realizację</p>
            </StyledBtnLine>
          </InformationWrapper>
          <NavigationWrapper
            data-sal="slide-up"
            data-sal-easing="ease"
            data-sal-duration="1000"
          >
            <ArrowWrapper>
              <ArrowTop
                onClick={this.nextProject}
                className={this.state.nextDisable ? "disable" : ""}
              >
                <Arrow />
              </ArrowTop>
              <ArrowBottom
                onClick={this.prevProject}
                className={this.state.prevDisable ? "disable" : ""}
              >
                <Arrow />
              </ArrowBottom>
            </ArrowWrapper>
            <StyledBtnArrow link="/realizacje">
              zobacz wszystkie realizacje
            </StyledBtnArrow>
          </NavigationWrapper>
        </LeftWrapper>
        <ImgSliderWrapper
          data-sal="slide-up"
          data-sal-easing="ease"
          data-sal-duration="1000"
        >
          {this.props.site.allStrapiHighlightedProjects.edges.map(
            (document) => (
              <>
                <Link
                  to={`/realizacje/${slugify(
                    this.props.site.allStrapiHighlightedProjects.edges[
                      this.state.curentProject
                    ].node.Name,
                    {
                      lower: true,
                    }
                  )}`}
                >
                  <StyledImgDesctop
                    fluid={document.node.Top_image.childImageSharp.fluid}
                    style={{
                      transform: `translateY(calc(${
                        this.state.curentProject * -100
                      }% - 30px * ${this.state.curentProject} ))`,
                    }}
                  />
                </Link>
                <StyledLinkImg
                  to={`/realizacje/${slugify(
                    this.props.site.allStrapiHighlightedProjects.edges[
                      this.state.curentProject
                    ].node.Name,
                    {
                      lower: true,
                    }
                  )}`}
                >
                  <StyledImgMobile
                    fluid={document.node.Top_image.childImageSharp.fluid}
                    style={{
                      transform: `translateX(calc(${
                        this.state.curentProject * -100
                      }%  ))`,
                    }}
                  />
                </StyledLinkImg>
              </>
            )
          )}

          <ArrowLeft
            onClick={this.prevProject}
            className={this.state.prevDisable ? "disable" : ""}
          >
            <MobileArrowHeader />
          </ArrowLeft>
          <ArrowRight
            onClick={this.nextProject}
            className={this.state.nextDisable ? "disable" : ""}
          >
            <MobileArrowHeader />
          </ArrowRight>
        </ImgSliderWrapper>
        <StyledTitleMobile>
          {
            this.props.site.allStrapiHighlightedProjects.edges[
              this.state.curentProject
            ].node.Name
          }
        </StyledTitleMobile>
      </SliderWrapper>
    );
  }
}

// export default HighlightedProjectsSlider;

export default function HighlightedProjectsSlider(props) {
  return (
    <StaticQuery
      query={graphql`
        query HighlightedProjectsSliderQuery {
          allStrapiHighlightedProjects {
            edges {
              node {
                Name
                Description_header
                Top_image {
                  childImageSharp {
                    fluid {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      `}
      render={(data) => <HighlightedProjects site={data} />}
    />
  );
}
