import styled from "styled-components";
import typographySizes from "src/assets/styles/typographySizes.js";
import colors from "src/assets/styles/colors.js";
import { Link } from "gatsby";
import mediaQuery from "src/assets/styles/mediaQuery";

const BtnLine = styled(Link)`
  font-size: ${typographySizes.s}px;
  @media (min-width: ${mediaQuery.max}) {
    font-size: ${typographySizes.maxS}vw;
    line-height: 1.33;
  }
  color: ${(props) => (props.White ? colors.white : colors.darkGrey)};
  line-height: 1.33;
  font-weight: 300;
  position: relative;
  cursor: pointer;
  padding: 0 10px;
  flex-shrink: 1;
  flex-grow: 0;
  display: inline-block;
  transition: all 0.5s;

  ::before {
    content: "";
    pointer-events: none;
    position: absolute;
    width: 77px;
    top: 50%;
    left: -15px;
    transform: translate(-100%, -50%);
    background-color: ${colors.mainColor};
    height: 1px;
    transition: all 0.5s;
  }
  ::after {
    content: "";
    position: absolute;
    width: 0;
    top: 50%;
    left: -15px;
    transform: translate(0%, -50%);
    background-color: ${colors.mainColor};
    height: 1px;
    transition: all 0.5s;
  }

  :hover {
    transition: all 0.5s;
    color: ${colors.mainColor};
    padding-bottom: 20px;

    ::after {
      top: calc(50% + 10px);

      transition: all 0.5s;
      width: calc(100% + 15px);
    }
    ::before {
      /* width: 0; */
      top: calc(50% + 10px);
      transition: all 0.5s;
    }
  }
  @media (max-width: ${mediaQuery.mobile}) {
    font-size: ${typographySizes.xs}px;
  }
`;

export default BtnLine;
